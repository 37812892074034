<template>
    <div id="location">
        <h2 is="sui-header" class="dividing">Location Management</h2>
        <sui-grid :columns="2">
            <sui-grid-row>
                <sui-grid-column>
                </sui-grid-column>
                <sui-grid-column text-align="right">
                    <sui-button-group size="small">
                        <sui-button color="green" icon="add building" @click="addLocation">Add Location</sui-button>
                        <sui-button color="blue" icon="edit" @click="editLocation">Edit Location</sui-button>
                    </sui-button-group>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-table celled selectable compact="">
            <sui-table-header full-width>
                <sui-table-row>
                    <sui-table-header-cell :width="5">
                        Title
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Address
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Status
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Created By
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent v-model="filterTitle"
                                   @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterAddress" @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell single-line>
                        <sui-dropdown item placeholder="" :options="optionsStatus" v-model="filterStatus"
                                      @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <sui-input placeholder="Search..." icon="filter" size="small" transparent
                                   v-model="filterCreatedBy" @input="reloadFirstPage"/>
                    </sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <location-row v-for="loc in location" :key="loc.id" :location="loc" @update:selectRow="selectRow"
                             @update:unselectRow="unselectRow"></location-row>
            </sui-table-body>
            <sui-table-footer>
                <sui-table-row>
                    <Pager :selected-page="selectedPage" :total-item-count="totalItemCount"
                           @update:selectedPage="loadPage"></Pager>
                </sui-table-row>
            </sui-table-footer>
        </sui-table>
        <sui-divider clearing hidden></sui-divider>
    </div>
</template>

<script>

    import axios from "axios";
    import _ from 'lodash';
    import Pager from "@/components/Pager";
    import LocationRow from "@/views/dashboard/location/LocationRow";

    export default {
        name: "Location",
        components: {LocationRow, Pager},
        data: function () {
            const status_options = ['All', 'Active', 'Inactive'];
            return {
                filterTitle: "",
                filterAddress: "",
                filterStatus: "",
                filterCreatedBy: "",

                optionsStatus: status_options.map((i) => {
                    return {
                        text: i,
                        value: i
                    }
                }),

                location: [],

                totalItemCount: 0,
                selectedPage: 0,
                itemPerPage: 10,

                selectedRow: null,
            }
        },
        created: async function () {
            this.debouncedReloadFirstPage = _.debounce(this.loadPage, 250);
            await this.loadPage({
                limit: this.itemPerPage,
                offset: 0,
            })

        },
        methods: {
            addLocation: function () {
                this.$router.push('/dashboard/location/add')
            },
            editLocation: function () {
                if (this.selectedRow) {
                    this.$router.push("/dashboard/location/edit/" + this.selectedRow.id)
                }
            },
            reloadFirstPage: async function () {
                await this.loadPage({
                    limit: this.itemPerPage,
                    offset: 0,
                })
            },
            selectRow: async function (row) {
                if (this.selectedRow) {
                    this.selectedRow.selected = false
                }
                this.selectedRow = row
            },
            unselectRow: async function () {
                this.selectedRow = null
            },
            loadPage: async function (v) {
                console.log("load page");
                console.log(v);
                axios({
                    "method": "POST",
                    url: "/api/location/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: v.limit,
                        offset: v.offset,

                        title: this.filterTitle,
                        address: this.filterAddress,
                        status: this.filterStatus,
                        createdBy: this.filterCreatedBy,
                    }
                }).then((result) => {
                    const {rows, count} = result.data;
                    this.location = rows.map((i) => {
                        i.selected = false;
                        if (i['super.firstName'] && i['super.lastName']){
                            i.createdBy = `${i['super.firstName']} ${i['super.lastName']}`;
                        }
                        return i;
                    })
                    this.totalItemCount = count;
                }).catch((e) => {
                    console.log(e);
                    //this.$router.push('/login');
                })
            }
        }
    }
</script>

<style scoped>
    tr:nth-child(2) th {
        padding: 5px 0;
    }

    tr:nth-child(2) th .ui.input {
        width: 100%;
    }
</style>
