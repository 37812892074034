<template>
    <sui-table-row @mousedown="selectRow" :selected="location.selected">
        <sui-table-cell>
            {{location.title}}
        </sui-table-cell>
        <sui-table-cell>
            {{location.address}}
        </sui-table-cell>
        <sui-table-cell>
            {{location.status}}
        </sui-table-cell>
        <sui-table-cell>
            {{location.createdBy}}
        </sui-table-cell>
    </sui-table-row>
</template>

<script>
    export default {
        name: "LocationRow",
        props: {
            location: {
                title: String,
                address: String,
                status: String,
                createdBy: String,

                selected: Boolean
            }
        },
        methods: {
            selectRow: function () {
                this.location.selected = !this.location.selected
                if (this.location.selected) {
                    this.$emit("update:selectRow", this.location);
                } else {
                    this.$emit("update:unselectRow", this.location);
                }
            }
        }
    }
</script>

<style scoped>

</style>